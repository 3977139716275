import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './styles.module.scss';

const markdown = `
  ## Thursday, 6 August 2020
  - Fixed an issue with square brackets "[]" and spaces that was preventing the emails from being sent in YCP
  - The result downloadable file now has the same file name as the uploaded one
`;

export const Changelog: React.FC = () => (
  <div className={styles.container}>
    <h1>Changelog</h1>
    <div className={styles.content}>
      <ReactMarkdown source={markdown}></ReactMarkdown>
    </div>
  </div>
)
