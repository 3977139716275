import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useVariables, Variable } from '../VariablesProvider';

export const VariableList: React.FC = () => {
  const { variables, updateVariable, addVariables, deleteVariable, clearVariables } = useVariables();
  const [hidden, setHidden] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const renderVariable = (variable: Variable) => 
     (
      <li key={variable.id} className={classNames(styles.VariableContainer, { [styles.invalid]: variable.y1Name === '' || variable.ycpName === '' })}>
        <div className={styles.inputContainer}>
          <input
            placeholder='Y1 Variable Name'
            type='text'
            value={variable.y1Name}
            onChange={e => updateVariable(variable.id, {...variable, y1Name: e.target.value })}
          />
          <span />
        </div>
        <div className={styles.spacer}></div>
        <div className={styles.inputContainer}>
          <input
            placeholder='YCP Variable Name'
            type='text'
            value={variable.ycpName}
            onChange={e => updateVariable(variable.id, {...variable, ycpName: e.target.value })}
          />
          <span />
        </div>
        <button onClick={e => deleteVariable(variable)}>x</button>
      </li>
    );

  const onClearVariables = () => {
    if (!window.confirm('Are you sure? This cannot be reversed')) {
      return;
    };

    clearVariables();
  };

  const onShareVariables = () => {
    const encodedVars = btoa(JSON.stringify(variables));
    navigator.clipboard.writeText(`${window.location.origin}#state=${encodedVars}`);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
 
  return (
    <div className={styles.VariableList}>
      <h1>Variable mapping</h1>
      <div className={styles.ButtonContainer}>
        { !hidden ? 
          <button onClick={e => setHidden(true)}>Hide</button> 
        :
          <button onClick={e => setHidden(false)}>Show</button>
        }
      </div>
      { !hidden ? variables.length > 0 ? 
        <ul>
          <li className={styles.heading}>
            <span>Y1</span>
            <span>
              ->
            </span>
            <span>
              YCP
            </span>
          </li>
          { variables.map(renderVariable) }
        </ul>
      : 
        <h3>Currently you have no variables mapped</h3>
      : <div/>}
      { !hidden &&
      <div className={styles.ButtonContainer}>
        <button onClick={e => addVariables([{custom: true, y1Name: '', ycpName: ''}])}>Add variable</button>
        <button onClick={onClearVariables}>Clear variables</button>
        <button onClick={onShareVariables}>{ copied? 'Copied link to clipboard' : 'Share variables' }</button>
      </div> }
    </div>
  )
};
