import React from 'react';
import { Variable, useVariables } from './VariablesProvider';
import * as handlebars from 'handlebars';
import { difference, uniq } from 'lodash';

interface Y1TemplateContext {
  template: string;
  updateTemplate: (newTemplate: string) => void;
}

interface FileNameContext {
  fileName: string;
  setFileName: (newFileName: string) => void;
}

const Y1TemplateContext = React.createContext<Y1TemplateContext>({template: '', updateTemplate: () => undefined});
const FileNameContext = React.createContext<FileNameContext>({ fileName: '', setFileName: () => undefined});
const YCPTemplateContext = React.createContext('');

function translateToHandlebars(template: string): string {
  return template
    .replace(/\{coupon}/g, '{{coupon}}')
    .replace(/\{\{foreach[^}]*}}/g, '{{#each items}}')
    .replace(/\{\{end}}/g, '{{/each}}');
}

function templateTransform(y1Template: string, varMappings: Variable[]): string {
  let newTemplate = translateToHandlebars(y1Template);

  varMappings
    .filter(mapping => mapping.ycpName !== '')
    .forEach((mapping) => {
    const searchRegexp = new RegExp(`\\{\\{${mapping.y1Name}}}`, 'g');
  
    let replaceValue: string;
    if (mapping.ycpName.includes('.')) {
      replaceValue = `{{ [${mapping.ycpName}] }}`;
    } else {
      replaceValue = `{{ ${mapping.ycpName} }}`;
    }
    newTemplate = newTemplate.replace(searchRegexp, replaceValue);
  });

  return newTemplate;
}

export const useY1Template = () => React.useContext(Y1TemplateContext);
export const useFileName = () => React.useContext(FileNameContext);
export const useYCPTemplate = () => React.useContext(YCPTemplateContext);
export const useTemplates: () => [Y1TemplateContext, string, FileNameContext] = () => [useY1Template(), useYCPTemplate(), useFileName()];

export const TemplateProvider: React.FC = ({children}) => {
  const [y1Template, setY1Template] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [ycpTemplate, setYcpTemplate] = React.useState('');

  const { variables, addVariables } = useVariables();

  React.useEffect(() => {
    setYcpTemplate(templateTransform(y1Template, variables));
  }, [variables, y1Template]);

  const updateTemplate: Y1TemplateContext['updateTemplate'] = (newTemplate: string) => {
    const templateVars: string[] = [];
  
    handlebars.registerHelper('helperMissing', (...args) => {
      const options = args[args.length - 1];
      templateVars.push(options.name);
    });

    // eslint-disable-next-line 
    const t = handlebars.compile(translateToHandlebars(newTemplate))({ items: [{}] });
    const varDifference = uniq(difference(templateVars, variables.map(v => v.y1Name)));

    addVariables(varDifference.map(v => ({y1Name: v, ycpName: '', custom: true})));

    setY1Template(newTemplate);
  };

  return (
    <Y1TemplateContext.Provider value={{template: y1Template, updateTemplate}}>
      <FileNameContext.Provider value={{ fileName, setFileName }}>
        <YCPTemplateContext.Provider value={ycpTemplate}>
          { children }
        </YCPTemplateContext.Provider>
      </FileNameContext.Provider>
    </Y1TemplateContext.Provider>
  );
}