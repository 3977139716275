import React from 'react';
import './App.scss';
import { VariablesProvider } from './VariablesProvider';
import { VariableList } from './VariableList';
import { TemplateProvider } from './TemplateProvider';
import { Template } from './Template';
import { Changelog } from './Changelog';

function App() {
  return (
    <VariablesProvider>
      <TemplateProvider>
      <div className="container">
        <VariableList></VariableList>
        <Template></Template>
        <Changelog></Changelog>
      </div>
      </TemplateProvider>
    </VariablesProvider>
  );
}

export default App;
