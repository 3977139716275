import React from 'react';
import createPersistedState from 'use-persisted-state';
import { v4 as uuidv4 } from 'uuid';
import * as qs from 'query-string';

export interface Variable {
  id: string;
  y1Name: string;
  ycpName: string;
  custom: boolean;
}

interface VariableContext {
  variables: Variable[];
  updateVariable: (id: string, newValue: Variable) => void;
  addVariables: (variable: Omit<Variable, 'id'>[]) => void;
  deleteVariable: (variable: Variable) => void,
  clearVariables: () => void,
}

const VariablesContext = React.createContext<VariableContext>({
  variables: [],
  updateVariable: () => undefined,
  addVariables: () => undefined,
  deleteVariable: () => undefined,
  clearVariables: () => undefined,
});

export const useVariables = () => React.useContext(VariablesContext);

const useVariablesState = createPersistedState('Variables');

export const VariablesProvider: React.FC = ({children}) => {
  const [variables, setVariables] = useVariablesState<Variable[]>([]);

  const updateVariable = (variableId: string, newValue: Variable) => 
    setVariables(variables.map(v => v.id === variableId ? newValue : v));

  const addVariables = (newVariables: Omit<Variable, 'id'>[]) => setVariables([...variables, ...newVariables.map(v => ({...v, id: uuidv4()}))]);

  const deleteVariable = (variable: Variable) => setVariables(variables.filter(v => v !== variable));

  const clearVariables = () => setVariables([]);

  React.useEffect(() => {
    const hashParams = qs.parse(window.location.hash);

    if (hashParams.state != null) {
      const decodedVars = JSON.parse(atob(hashParams.state as string));
      setVariables(decodedVars);
    }
  }, [setVariables]);

  return (
    <VariablesContext.Provider value={{ variables, updateVariable, addVariables, deleteVariable, clearVariables }}>
      { children }
    </VariablesContext.Provider>
  );
};
