import React from 'react';
import { DiffEditor as MonacoDiff } from '@monaco-editor/react';

export const DiffEditor: React.FC<{oldTemplate: string, newTemplate: string}> = ({oldTemplate, newTemplate}) => {
  

  return (
    <MonacoDiff width="75%" height="70vh" theme="dark" original={oldTemplate} modified={newTemplate} language="handlebars"></MonacoDiff>
  );
};
