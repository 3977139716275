import React from 'react';
import styles from './styles.module.scss';
import { useTemplates } from '../TemplateProvider';
import { DiffEditor } from './DiffEditor';

export const Template: React.FC = () => {
  let fileReader: FileReader;

  const [{updateTemplate, template}, ycpTemplate, {fileName, setFileName}] = useTemplates();


  const onFileLoad = () => {
    const content = fileReader.result;

    updateTemplate(content as string);    
  };

  const onFileChosen = (file: File) => {
    fileReader = new FileReader();
    fileReader.onloadend = () => onFileLoad();
    fileReader.readAsText(file);
    setFileName(file.name);
  };

  return (
    <div className={styles.Template}>
      <h1>Template transformation</h1>
      <div className={styles.ButtonContainer}>
        <input type='file' id='file' onChange={(e) => onFileChosen(e.target.files![0])}></input>
        <label htmlFor='file'>Upload Y1 Template</label>
        <a href={`data:text/html;charset=utf-8,${encodeURIComponent(ycpTemplate)}`} download={fileName}>Download YCP Template</a>
      </div>
      <DiffEditor oldTemplate={template} newTemplate={ycpTemplate}></DiffEditor>
    </div>
  );
};